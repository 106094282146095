const MobileDisconnectButtonBackground = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92"
    height="19"
    viewBox="0 0 92 19"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_b_2899_897)">
      <path
        d="M3.64093 16.4974L5.50326 17.9932C5.88796 18.3022 6.36661 18.4706 6.86005 18.4706H11.7852H23.1602H45.9102H89.2435C90.4401 18.4706 91.4102 17.5006 91.4102 16.304V9.2623V5.69986C91.4102 5.04144 91.1108 4.41875 90.5965 4.00759L88.4486 2.29027L86.3219 0.545517C85.9344 0.227671 85.4488 0.0539551 84.9476 0.0539551H80.0352H68.6602H45.9102H2.57683C1.38021 0.0539551 0.410156 1.02401 0.410156 2.22062V9.2623V12.8367C0.410156 13.4883 0.703413 14.1053 1.20868 14.5167L3.64093 16.4974Z"
        fill="url(#paint0_linear_2899_897)"
        fillOpacity="0.5"
      />
      <path
        d="M88.2795 2.50182L88.2768 2.49966L86.1501 0.754906C85.8111 0.476789 85.3861 0.324789 84.9476 0.324789H80.0352H68.6602H45.9102H2.57683C1.52979 0.324789 0.68099 1.17358 0.68099 2.22062V9.2623V12.8367C0.68099 13.4068 0.937589 13.9467 1.37969 14.3067L3.81052 16.2863L3.81194 16.2874L5.67285 17.7821C6.00947 18.0524 6.42829 18.1998 6.86005 18.1998H11.7852H23.1602H45.9102H89.2435C90.2905 18.1998 91.1393 17.351 91.1393 16.304V9.2623V5.69986C91.1393 5.12375 90.8774 4.57889 90.4274 4.21912L88.2795 2.50182Z"
        stroke="url(#paint1_linear_2899_897)"
        strokeWidth="0.541668"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_2899_897"
        x="-21.2566"
        y="-21.6128"
        width="134.333"
        height="61.7501"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10.8334" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2899_897" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2899_897"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2899_897"
        x1="0.410156"
        y1="0.0539584"
        x2="91.4102"
        y2="18.4706"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#040E20" />
        <stop offset="1" stopColor="#05182F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2899_897"
        x1="122.776"
        y1="24.1272"
        x2="98.2394"
        y2="56.3676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#119BFF" stopOpacity="0" />
        <stop offset="1" stopColor="#119BFF" stopOpacity="0.7" />
      </linearGradient>
    </defs>
  </svg>
);
export default MobileDisconnectButtonBackground;
