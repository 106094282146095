import { useEffect } from "react";
import { setMainError, setQueryInDmdSwapPayload, setQueryToken } from "../store/reducers/mainSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const QUERY_PARAMS = {
  token: "t",
  inDmdSwapPayload: "idsp",
};

const IS_AUTO_ADD_QUERY = false;

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isQueryTokenHandled = !!useSelector(s => s.main.queryToken);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development" || !IS_AUTO_ADD_QUERY) return;

    setSearchParams({
      [QUERY_PARAMS.token]:
        "eyJhbGciOiJSUzI1NiIsImtpZCI6InB1YmxpYzo2NzQ2QjA5NC0zODNCLTRFMDYtQjA0OS04OUU4MTU1NjdBOUQiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOlsiaWRkOmZhNmU4NjcxLTEwNTUtNDA1Ny05ZTRlLTU1NDNjYjU2MDIxYiIsImVudk5hbWU6cHJvZHVjdGlvbiIsImVudklkOjg1NDRlOWU3LWFmZTAtNDEwZC04OWQyLTAxMTFmMjY5OTA2ZiIsInVwaWQ6ZDQyODdjZGMtYzRlYy00OGU4LWFlNDQtNjZiMDA1YzdkNDNhIl0sImV4cCI6MTcwNTQxNTQ0NywiaWF0IjoxNzA1NDExODQ3LCJpZGQiOiJmYTZlODY3MS0xMDU1LTQwNTctOWU0ZS01NTQzY2I1NjAyMWIiLCJpc3MiOiJodHRwczovL3BsYXllci1hdXRoLnNlcnZpY2VzLmFwaS51bml0eS5jb20iLCJqdGkiOiJmNzk3MTEwZS1jNGU5LTQ4ZmQtOGU3NC00YTY5ZmRiOWUzZTIiLCJuYmYiOjE3MDU0MTE4NDcsInByb2plY3RfaWQiOiJkNDI4N2NkYy1jNGVjLTQ4ZTgtYWU0NC02NmIwMDVjN2Q0M2EiLCJzaWduX2luX3Byb3ZpZGVyIjoiYW5vbnltb3VzIiwic3ViIjoiTHlkWE9mYTVZOWR2STVkVHhGWWNWblVZak5POCIsInRva2VuX3R5cGUiOiJhdXRoZW50aWNhdGlvbiIsInZlcnNpb24iOiIxIn0.eMX8S3BjvwFubQApR0TEWwTZVPaF2tHlZ7OVKld9yM7AFw1oKZByUszebRzJzNjfAB-WGbFsYArmVXaNV5tCXhSROSRxrsPF923JCWycRD_Ewc_jv3mDPfNUPowbaaEP0_RuVaPYzaLv4LFsLeqPCCMnas0KTPTrLlON6gtVzXacwl0ZDjqXu757ohHmqVOk8fL7_8_iD2QwVh5-ZMdaFNdg3kNimP4v93hZvise8cH_6hIWzJyZgvYU4tduaFZxPiZpANNSNXUoxhbHKkPGNHphuImyY8Eb9cMzhidfJNUOGCOk_0Jl_GpPd4MKKbBGYxOkSBcjK3JaYcP9VrmHXg",
      [QUERY_PARAMS.inDmdSwapPayload]:
        "WyIweDhjRTNBMjA5OTljRjMzM0JlODBiMzY1MEZlNTU1MUQ4NzFlMDk3OTEiLCAxMDAwMDAwMDAwMDAwMDAwMDAwMDAsIHRydWUsIDE3MDkxMjY2ODgsIDI4LCAiMHgyZjc4M2VjYmQyN2E0NTRjYTYyMTI1ZDFhZTU0YzJjMWU2ZDA5MmNkZGIwN2ZmYWVlNTQyNzJlOWYzYWNhNjlkIiwgIjB4NmIxZjY3YTc1ZDg0NzdhZTE1ODM5N2ZkMzY4YjNmMTU0NzExYTg1MTk2YTgzMTNjMjNhNmNjNzVhZmZmYjMxNCJd",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isQueryTokenHandled) {
      dispatch(setMainError({ message: "" }));

      return;
    }

    const queryToken = searchParams.get(QUERY_PARAMS.token);

    if (!queryToken) {
      dispatch(setMainError({ message: "No auth data, please try again through Game" }));

      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const inDmdSwapPayload = searchParams.get(QUERY_PARAMS.inDmdSwapPayload);

    if (inDmdSwapPayload) {
      dispatch(setQueryInDmdSwapPayload(inDmdSwapPayload));
      queryParams.delete(QUERY_PARAMS.inDmdSwapPayload);
    }

    queryParams.delete(QUERY_PARAMS.token);
    navigate(
      {
        search: queryParams.toString(),
      },
      { replace: true },
    );
    dispatch(setQueryToken(queryToken));
  }, [dispatch, isQueryTokenHandled, navigate, searchParams]);
};
