const DecorativeVectorRight = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={188} height={17} fill="none" className={className}>
    <path
      stroke="url(#a)"
      strokeWidth={2}
      d="M188 1H81.317a4 4 0 0 0-2.278.713L59.454 15.287a4 4 0 0 1-2.279.713H32.623a4 4 0 0 1-2.766-1.111L23.52 8.822a4 4 0 0 0-2.767-1.111H0"
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-60.54582 -5.91737 33092.59847 -338599.71249 55.135 -8.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDB8E" />
        <stop offset={1} stopColor="#8DCCE7" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);
export default DecorativeVectorRight;
