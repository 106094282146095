import { createSlice } from "@reduxjs/toolkit";
import { NAVIGATION_STEPS } from "../../config";

const initialState = {
  isMobile: false,
  isMetamaskBrowser: false,
  queryToken: "",
  queryInDmdSwapPayload: "",
  loader: {
    isLoading: false,
    loadingText: "",
  },
  mainError: {
    message: "",
  },
  navigationSteps: {
    history: [],
    current: NAVIGATION_STEPS.connect,
  },
  navigationStepsHistory: [],
  navigationStep: NAVIGATION_STEPS.connect,
  reassignModal: {
    isOpen: false,
    oldWallet: "",
    onConfirm: () => {},
    onDecline: () => {},
  },
  successModal: {
    isOpen: false,
  },
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsMetamaskBrowser: (state, action) => {
      state.isMetamaskBrowser = action.payload;
    },
    setQueryToken: (state, action) => {
      state.queryToken = action.payload;
    },
    setQueryInDmdSwapPayload: (state, action) => {
      state.queryInDmdSwapPayload = action.payload;
    },
    setLoaderData: (state, action) => {
      state.loader.isLoading = action.payload.isLoading ?? state.loader.isLoading;
      state.loader.loadingText = action.payload.loadingText || "";
    },
    setMainError: (state, action) => {
      state.mainError.message = action.payload.message;
    },
    setNavigationStep: (state, action) => {
      state.navigationSteps.history.push(state.navigationSteps.current);
      state.navigationSteps.current = action.payload;
    },
    goBack: state => {
      const previousStep = state.navigationSteps.history[state.navigationSteps.history.length - 1];
      state.navigationSteps.current = previousStep;
      state.navigationSteps.history.pop();
    },
    setReassignModal: (state, action) => {
      state.reassignModal = action.payload;
    },
    setSuccessModal: (state, action) => {
      state.successModal = action.payload;
    },
  },
});

export const {
  setMobile,
  setIsMetamaskBrowser,
  setQueryToken,
  setQueryInDmdSwapPayload,
  setLoaderData,
  setMainError,
  setNavigationStep,
  setReassignModal,
  goBack,
  setSuccessModal,
} = mainSlice.actions;

export default mainSlice.reducer;
