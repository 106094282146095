import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playerId: "",
};

export const userSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setPlayerId: (state, action) => {
      state.playerId = action.payload;
    },
    clearUserState: () => initialState,
  },
});

export const { setPlayerId, clearUserState } = userSlice.actions;

export default userSlice.reducer;
