import { useDispatch, useSelector } from "react-redux";
import { setReassignModal } from "../../../store/reducers/mainSlice";
import { MainButton } from "../../buttons/MainButton/MainButton";
import styles from "./styles.module.scss";
import { getShortWallet } from "../../../utils/common";
import DeclineButton from "../../buttons/DeclineButton/DeclineButton";
import Modal from "../Modal/Modal";

const ReassignWalletModal = () => {
  const isOpen = useSelector(s => s.main.reassignModal.isOpen);
  const onConfirm = useSelector(s => s.main.reassignModal.onConfirm);
  const onDecline = useSelector(s => s.main.reassignModal.onDecline);
  const wallet = useSelector(s => s.connect.wallet);
  const oldWallet = useSelector(s => s.main.reassignModal.oldWallet);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setReassignModal({ isOpen: false }));
  };

  const handleDecline = () => {
    onDecline();
    closeModal();
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="Reassign Modal">
      <p className={styles.text}>
        You have previously connected another wallet{" "}
        <span title={oldWallet}>&quot;{getShortWallet(oldWallet)}&quot;</span>, now you are trying
        to connect wallet <span title={wallet}>&quot;{getShortWallet(wallet)}&quot;</span>. Do you
        confirm reassignment?
      </p>
      <div className={styles.buttonsBlock}>
        <DeclineButton onClick={handleDecline} />
        <MainButton className={styles.mainButton} onClick={handleConfirm}>
          Confirm
        </MainButton>
      </div>
    </Modal>
  );
};

export default ReassignWalletModal;
