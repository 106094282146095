import { setLoaderData, setMainError, setNavigationStep } from "../store/reducers/mainSlice";
import { NAVIGATION_STEPS } from "../config";
import { dmtClient } from "./axios";
import { API_ROUTES } from "../config/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setHistoryMode = () => async (dispatch, getState) => {
  dispatch(setLoaderData({ isLoading: true }));
  await dispatch(fetchHistory({ page: 1 }));
  const isError = getState().history.isError;

  if (isError) {
    dispatch(setMainError({ message: "An error occurred while fetching history" }));
  } else {
    dispatch(setNavigationStep(NAVIGATION_STEPS.history));
  }

  dispatch(setLoaderData({ isLoading: false }));
};

export const fetchHistory = createAsyncThunk("history/fetchHistory", async ({ page }) => {
  const response = await dmtClient(`${API_ROUTES.exchangeLog}?page=${page}`);

  return response.data;
});

export const fetchMoreHistory = () => (dispatch, getState) => {
  const currentPage = getState().history.page;

  dispatch(fetchHistory({ page: currentPage + 1 }));
};
