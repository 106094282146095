import { createSlice } from "@reduxjs/toolkit";
import { fetchHistory } from "../../utils/history";

const initialState = {
  list: [],
  page: 1,
  isLoading: false,
  hasMore: true,
  isError: false,
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchHistory.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchHistory.fulfilled, (state, action) => {
        state.hasMore = !!action.payload.next;
        const page = action.meta.arg.page;
        const isFirstRequest = page === 1;
        state.list = isFirstRequest
          ? action.payload.results
          : state.list.concat(action.payload.results);
        state.page = page;
        state.isLoading = false;
      })
      .addCase(fetchHistory.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default historySlice.reducer;
