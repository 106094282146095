export const HeaderBackgroundIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="610"
    height="2"
    viewBox="0 0 610 2"
    fill="none"
    className={className}
  >
    <path d="M0 1H219.51H610" stroke="url(#paint0_linear_911_933)" strokeWidth="2" />
    <defs>
      <linearGradient
        id="paint0_linear_911_933"
        x1="747.558"
        y1="57.5013"
        x2="569.351"
        y2="391.632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0075FF" stopOpacity="0" />
        <stop offset="1" stopColor="#119BFF" />
      </linearGradient>
    </defs>
  </svg>
);
