import ReactModal from "react-modal";
import styles from "./styles.module.scss";
import classNames from "classnames";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(2, 9, 25, 0.80)",
    zIndex: 9999,
  },
};

ReactModal.setAppElement("#root");

const Modal = ({ isOpen, onRequestClose, contentLabel, children, className }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
      className={classNames(styles.content, { [className]: !!className })}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
