const ConnectButtonBackground = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={424}
    height={150}
    fill="none"
    className={className}
  >
    <g filter="url(#a.connect)">
      <path
        fill="url(#b.connect)"
        d="M51 56.888V93a2 2 0 0 0 2 2h308.962c.496 0 .974-.184 1.341-.517l9.039-8.178a2 2 0 0 0 .658-1.483V49a2 2 0 0 0-2-2H62.02a2 2 0 0 0-1.318.495l-9.019 7.888A2 2 0 0 0 51 56.888Z"
      />
      <path
        stroke="url(#c.connect)"
        strokeWidth={2}
        d="M51 56.888V93a2 2 0 0 0 2 2h308.962c.496 0 .974-.184 1.341-.517l9.039-8.178a2 2 0 0 0 .658-1.483V49a2 2 0 0 0-2-2H62.02a2 2 0 0 0-1.318.495l-9.019 7.888A2 2 0 0 0 51 56.888Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b.connect"
        x1={51}
        x2={417.562}
        y1={47}
        y2={142.682}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.183} stopColor="#B2E8FF" />
        <stop offset={0.433} stopColor="#B1FFEB" />
        <stop offset={0.854} stopColor="#FFF8B4" />
        <stop offset={1} stopColor="#FFF9B9" />
      </linearGradient>
      <linearGradient
        id="c.connect"
        x1={378.638}
        x2={57.959}
        y1={95}
        y2={0.166}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.474} stopColor="#91CBE6" />
        <stop offset={0.969} stopColor="#DED198" />
      </linearGradient>
      <filter
        id="a.connect"
        width={424}
        height={150}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={25} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.568627 0 0 0 0 0.882353 0 0 0 0 0.862745 0 0 0 0.4 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_911_696" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_911_696" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default ConnectButtonBackground;
