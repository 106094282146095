import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TokenInput from "../TokenInput/TokenInput";
import { SWAP_FORM_FIELDS } from "./constants";
import { MainButton } from "../buttons/MainButton/MainButton";
import { capitalizeString } from "../../utils/common";
import styles from "./styles.module.scss";
import { tokenToTokenSwap } from "../../utils/swap";
import { getBalances } from "../../utils/balance";
import { useState } from "react";
import { useSwapCurrentStatus } from "../../hooks/useSwapCurrentStatus";
import classNames from "classnames";
import { setLoaderData, setSuccessModal } from "../../store/reducers/mainSlice";

const SwapForm = () => {
  const dispatch = useDispatch();
  const balanceFrom = useSelector(s => s.swap.balance.from);
  const tokenFrom = useSelector(s => s.swap.tokenFrom);
  const tokenTo = useSelector(s => s.swap.tokenTo);
  const addresses = useSelector(s => s.swap.addresses);
  const balanceTo = useSelector(s => s.swap.balance.to);
  const [manualInput, setManualInput] = useState(SWAP_FORM_FIELDS.from);
  const { currentStatus, setErrorStatus, STATUS_TYPES } = useSwapCurrentStatus();
  const isBuyMode = tokenFrom.address === addresses.externalToken;

  const {
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useFormContext();

  const onSubmit = async values => {
    try {
      await dispatch(tokenToTokenSwap(values.from));
      await dispatch(getBalances());
      reset();
      dispatch(setSuccessModal({ isOpen: true }));
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        setErrorStatus("Action rejected!");
      } else if (
        error.code === "CALL_EXCEPTION" &&
        error.reason === "BEP40: transfer amount exceeds allowance"
      ) {
        setErrorStatus("Wrong amount: transfer amount exceeds allowance!");
      } else {
        setErrorStatus(error.shortMessage || "");
      }

      console.dir(error);
    } finally {
      dispatch(setLoaderData({ isLoading: false }));
    }
  };

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TokenInput
          name={SWAP_FORM_FIELDS.from}
          label="Give"
          setManualInput={setManualInput}
          tokenData={tokenFrom}
          balance={balanceFrom}
        />
        <TokenInput
          name={SWAP_FORM_FIELDS.to}
          label="Receive"
          setManualInput={setManualInput}
          tokenData={tokenTo}
          balance={balanceTo}
        />
        <MainButton type="submit" disabled={!isValid || isSubmitting}>
          {isBuyMode ? "Buy" : "Sell"}
        </MainButton>
        {currentStatus.text && (
          <span
            className={classNames({
              [styles.errorMessage]: currentStatus.type === STATUS_TYPES.ERROR,
              [styles.successMessage]: currentStatus.type === STATUS_TYPES.SUCCESS,
            })}
          >
            {capitalizeString(currentStatus.text)}
          </span>
        )}
      </form>
    </div>
  );
};

export default SwapForm;
