import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

// Components
import SwapForm from "./SwapForm";

// Constants
import { SWAP_FORM_FIELDS } from "./constants";
import { validationSchema } from "./validationSchema";

const SwapFormContainer = () => {
  const balanceFrom = useSelector(s => s.swap.balance.from);
  const tokenFrom = useSelector(s => s.swap.tokenFrom);
  const tokenTo = useSelector(s => s.swap.tokenTo);

  const methods = useForm({
    defaultValues: {
      [SWAP_FORM_FIELDS.from]: 0,
      [SWAP_FORM_FIELDS.to]: 0,
    },
    resolver: yupResolver(validationSchema),
    context: {
      tokenFromName: tokenFrom.name,
      maxFromAmount: balanceFrom,
      maxFromDecimals: tokenFrom.decimals,
      tokenToName: tokenTo.name,
      maxToDecimals: tokenFrom.decimals,
    },
    mode: "all",
  });

  return (
    <FormProvider {...methods}>
      <SwapForm />
    </FormProvider>
  );
};

export default SwapFormContainer;
