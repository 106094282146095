import styles from "./styles.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleConnect, handleDisconnect } from "../../utils/connect";
import { useQueryParams } from "../../hooks/useQueryParams";
import SwapFormContainer from "../../components/SwapForm/SwapFormContainer";
import { setBuyMode, setSellMode } from "../../utils/swap";
import GlobalLoader from "../../components/GlobalLoader/GlobalLoader";
import { setLoaderData, setMobile, setNavigationStep } from "../../store/reducers/mainSlice";
import { MainButton } from "../../components/buttons/MainButton/MainButton";
import ConnectButton from "../../components/buttons/ConnectButton/ConnectButton";
import DisconnectButton from "../../components/buttons/DisconnectButton/DisconnectButton";
import OuterButtonsWrapper from "../../components/OuterButtonsWrapper/OuterButtonsWrapper";
import { NAVIGATION_STEPS } from "../../config";
import HistoryBlock from "../../components/HistoryBlock/HistoryBlock";
import { setHistoryMode } from "../../utils/history";
import ReassignWalletModal from "../../components/modals/ReassignWalletModal/ReassignWalletModal";
import { getShortWallet } from "../../utils/common";
import InDMDSellBlock from "../../components/InDmdSwapBlock/InDmdSwapBlock";
import SuccessModal from "../../components/modals/SuccessModal/SuccessModal";
import mobileLogo from "../../assets/images/mobile-logo.png";
import { detectMetamaskBrowser } from "../../utils/main";

const Homepage = () => {
  const dispatch = useDispatch();
  const isQueryTokenHandled = !!useSelector(s => s.main.queryToken);
  const wallet = useSelector(s => s.connect.wallet);
  const isSellTokenEnabled = useSelector(s => s.swap.isSellTokenEnabled);
  const mainError = useSelector(s => s.main.mainError);
  const navigationStep = useSelector(s => s.main.navigationSteps.current);
  const isMobile = useSelector(s => s.main.isMobile);
  const isWalletConnected = !!wallet;

  useQueryParams();

  const connect = async () => {
    try {
      await dispatch(handleConnect());
    } catch {}
  };

  const disconnect = async () => {
    await dispatch(handleDisconnect());
  };

  const shortWallet = getShortWallet(wallet);

  const setSwapMode = async isBuy => {
    dispatch(setLoaderData({ isLoading: true }));

    try {
      const method = isBuy ? setBuyMode : setSellMode;
      await dispatch(method());
      dispatch(setNavigationStep(NAVIGATION_STEPS.swap));
    } finally {
      dispatch(setLoaderData({ isLoading: false }));
    }
  };

  useEffect(() => {
    dispatch(detectMetamaskBrowser());
  }, [dispatch]);

  useEffect(() => {
    const mobileMediaQuery = "(max-width: 767px)";
    const mediaQueryList = window.matchMedia(mobileMediaQuery);

    dispatch(setMobile(mediaQueryList.matches));

    const handleChange = event => {
      dispatch(setMobile(event.matches));
    };

    try {
      // Chrome, Firefox etc.
      mediaQueryList.addEventListener("change", handleChange);

      return () => {
        mediaQueryList.removeEventListener("change", handleChange);
      };
    } catch (e1) {
      try {
        // Safari
        mediaQueryList.addListener(handleChange);

        return () => {
          mediaQueryList.removeListener(handleChange);
        };
      } catch (e2) {
        console.error(e2);
      }
    }
  }, [dispatch]);

  return (
    <div className={styles.pageWrapper}>
      {isMobile && <img src={mobileLogo} alt="Dominator logo" className={styles.mobileLogo} />}
      <OuterButtonsWrapper>
        <div className={styles.contentWrapper}>
          <div className={styles.wingBottom}></div>
          <div className={styles.wingTop}></div>
          {navigationStep === NAVIGATION_STEPS.connect && isQueryTokenHandled && (
            <div className={styles.unauthorizedBlock}>
              <h2 className={styles.connectTitle}>Connect wallet</h2>
              <ConnectButton onClick={connect} />
              <ReassignWalletModal />
            </div>
          )}
          {isWalletConnected && navigationStep !== NAVIGATION_STEPS.connect && (
            <div className={styles.contentHeader}>
              <DisconnectButton onClick={disconnect} />
              <p title={wallet} className={styles.shortWallet}>
                {shortWallet}
              </p>
            </div>
          )}
          {navigationStep === NAVIGATION_STEPS.operationSelect && (
            <ul className={styles.operationButtonsList}>
              <li>
                <MainButton
                  className={styles.operationButton}
                  onClick={() => {
                    setSwapMode(true);
                  }}
                >
                  Purchase
                </MainButton>
              </li>
              <li>
                <MainButton
                  className={styles.operationButton}
                  disabled={!isSellTokenEnabled}
                  title={isSellTokenEnabled ? null : "Currently disabled"}
                  onClick={() => {
                    setSwapMode(false);
                  }}
                >
                  Sell
                </MainButton>
              </li>
              <li>
                <MainButton
                  className={styles.operationButton}
                  onClick={() => {
                    dispatch(setHistoryMode());
                  }}
                >
                  History
                </MainButton>
              </li>
            </ul>
          )}
          {navigationStep === NAVIGATION_STEPS.swap && <SwapFormContainer />}
          {navigationStep === NAVIGATION_STEPS.history && <HistoryBlock />}
          {navigationStep === NAVIGATION_STEPS.inDmdSwap && <InDMDSellBlock />}
          {mainError.message && <p className={styles.mainError}>{mainError.message}</p>}
        </div>
      </OuterButtonsWrapper>
      <GlobalLoader />
      <SuccessModal />
    </div>
  );
};

export default Homepage;
