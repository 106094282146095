const DisconnectButtonBackground = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={168} height={34} fill="none" className={className}>
    <g filter="url(#a.disconnect)">
      <path
        fill="url(#b.disconnect)"
        fillOpacity={0.5}
        d="m5.965 30.357 3.438 2.762a4 4 0 0 0 2.505.881H164a4 4 0 0 0 4-4V10.423a4.001 4.001 0 0 0-1.502-3.124l-3.965-3.17-3.927-3.222A4 4 0 0 0 156.069 0H4a4 4 0 0 0-4 4v19.599A4 4 0 0 0 1.474 26.7l4.49 3.656Z"
      />
      <path
        stroke="url(#c.disconnect)"
        d="m162.22 4.52-.005-.005-3.926-3.22a3.5 3.5 0 0 0-2.22-.795H4A3.5 3.5 0 0 0 .5 4v19.599a3.5 3.5 0 0 0 1.29 2.714l4.489 3.655.001.001 3.436 2.76a3.5 3.5 0 0 0 2.192.771H164a3.5 3.5 0 0 0 3.5-3.5V10.423c0-1.063-.484-2.07-1.314-2.733l-3.966-3.17Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b.disconnect"
        x1={0}
        x2={168}
        y1={0}
        y2={34}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#040E20" />
        <stop offset={1} stopColor="#05182F" />
      </linearGradient>
      <linearGradient
        id="c.disconnect"
        x1={225.905}
        x2={180.608}
        y1={44.443}
        y2={103.964}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#119BFF" stopOpacity={0} />
        <stop offset={1} stopColor="#119BFF" stopOpacity={0.7} />
      </linearGradient>
      <filter
        id="a.disconnect"
        width={248}
        height={114}
        x={-40}
        y={-40}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={20} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_911_769" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_911_769" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default DisconnectButtonBackground;
