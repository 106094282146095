import { ethers } from "ethers";
import { getContract } from "./contract";
import { setSwapBalances } from "../store/reducers/swapSlice";

export const getTokenBalance = async (tokenAddress, walletAddress, provider) => {
  if (!walletAddress) return 0;

  const signer = await provider.getSigner();

  const tokenContract = await getContract({
    address: tokenAddress,
    signer,
    name: "Erc20",
  });

  const tokenBalance = await tokenContract.balanceOf(walletAddress);

  return ethers.formatUnits(tokenBalance, 18);
};

export const getBalances = () => async (dispatch, getState) => {
  const { provider, wallet } = getState().connect;
  const { tokenFrom, tokenTo } = getState().swap;
  const balanceFrom = await getTokenBalance(tokenFrom.address, wallet, provider);
  const balanceTo = await getTokenBalance(tokenTo.address, wallet, provider);

  dispatch(setSwapBalances({ balanceFrom, balanceTo }));
};
