export const DecorativeVectorRight = ({ className }) => (
  <svg
    width="147"
    height="15"
    viewBox="0 0 147 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 14H56.6701L69.7917 1H110.298H130.075H147"
      stroke="url(#paint0_radial_1202_980)"
      strokeWidth="2"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1202_980"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(79.318 35.0964) rotate(171.034) scale(45.1764 101079)"
      >
        <stop stopColor="#119BFF" />
        <stop offset="1" stopColor="#0075FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
