import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";

const GlobalLoader = () => {
  const { isLoading, loadingText } = useSelector(s => s.main.loader);

  if (!isLoading) return null;

  return (
    <div className={styles.loaderWrapper}>
      {loadingText && <p className={styles.statusText}>{loadingText}...</p>}
      <Loader statusText={loadingText} />
    </div>
  );
};

export default GlobalLoader;
