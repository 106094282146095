export const MobileDecorativeVectorLeft = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="7"
    viewBox="0 0 63 7"
    fill="none"
    className={className}
  >
    <path
      d="M62.9961 6.49609H37.0312L30.4093 0.623902H14.8129H0.00069242"
      stroke="url(#paint0_radial_2951_989)"
      strokeWidth="0.901669"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2951_989"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.6539 -0.8844) rotate(-6.8667) scale(20.5936 35528.7)"
      >
        <stop stopColor="#119BFF" />
        <stop offset="1" stopColor="#0075FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
