import styles from "./styles.module.scss";
import { MainButton } from "../buttons/MainButton/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { DMD_TOKEN_DATA } from "../../config/tokens";
import DeclineButton from "../buttons/DeclineButton/DeclineButton";
import { closePage } from "../../utils/common";
import { handleInDmdSwap } from "../../utils/swap";

const InDmdSwapBlock = () => {
  const dispatch = useDispatch();
  const inDmdAmount = useSelector(s => s.swap.inDmdSwapData.parsedPayload.amount);
  const isOutput = useSelector(s => s.swap.inDmdSwapData.parsedPayload.isOutput);
  const isLoading = useSelector(s => s.main.loader.isLoading);
  const isMetamaskBrowser = useSelector(s => s.main.isMetamaskBrowser);

  const onSubmit = async () => {
    dispatch(handleInDmdSwap());
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        Confirm the {isOutput ? "withdrawal" : "accrual"} of {inDmdAmount} {DMD_TOKEN_DATA.name}{" "}
        {isOutput ? "to" : "from"} the blockchain
      </p>
      <div className={styles.buttonsBlock}>
        <DeclineButton onClick={() => closePage(isMetamaskBrowser)} />
        <MainButton className={styles.mainButton} onClick={onSubmit} disabled={isLoading}>
          Confirm
        </MainButton>
      </div>
    </div>
  );
};

export default InDmdSwapBlock;
