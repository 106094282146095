import * as yup from "yup";
import { SWAP_FORM_FIELDS } from "./constants";

export const validationSchema = yup.object().shape({
  [SWAP_FORM_FIELDS.from]: yup
    .number()
    .typeError("Must be a valid a number")
    .transform((value, rawValue) => (rawValue === "" ? undefined : value))
    .required("Is required field")
    .positive("Must be positive")
    .test("TooManyDecimals", (value, { createError, options, originalValue }) => {
      const maxDecimals = options.context.maxFromDecimals;
      const tokenName = options.context.tokenFromName;
      const decimals = originalValue.split?.(".")[1]?.length || 0;

      if (decimals > maxDecimals) {
        return createError({ message: `${tokenName}: too many decimals` });
      }

      return true;
    })
    .test("Balance", "Insufficient Balance", (value, { options }) => {
      const maxFromAmount = options.context.maxFromAmount;

      return value <= maxFromAmount;
    }),
  [SWAP_FORM_FIELDS.to]: yup
    .number()
    .typeError("Must be a valid a number")
    .transform((value, rawValue) => (rawValue === "" ? undefined : value))
    .required("Is required field")
    .positive("Must be positive")
    .test("TooManyDecimals", (value, { createError, options, originalValue }) => {
      const maxDecimals = options.context.maxToDecimals;
      const tokenName = options.context.tokenToName;
      const decimals = originalValue.split?.(".")[1]?.length || 0;

      if (decimals > maxDecimals) {
        return createError({ message: `${tokenName}: too many decimals` });
      }

      return true;
    }),
});
