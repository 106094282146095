import styles from "./styles.module.scss";
import DisconnectButtonBackground from "./icons/DisconnectButtonBackground";
import { useSelector } from "react-redux";
import MobileDisconnectButtonBackground from "./icons/MobileDisconnectButtonBackground";

const DisconnectButton = ({ onClick }) => {
  const isMobile = useSelector(s => s.main.isMobile);

  return (
    <button onClick={onClick} className={styles.disconnectButton}>
      {isMobile ? (
        <MobileDisconnectButtonBackground className={styles.background} />
      ) : (
        <DisconnectButtonBackground className={styles.background} />
      )}
      <div className={styles.content}>Disconnect</div>
    </button>
  );
};

export default DisconnectButton;
