const MobileDecorativeVectorLeft = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99"
    height="10"
    viewBox="0 0 99 10"
    fill="none"
    className={className}
  >
    <path
      d="M98.0597 8.72307H43.513C42.7112 8.72307 41.9279 8.48208 41.2647 8.03138L32.1175 1.81485C31.4543 1.36415 30.671 1.12316 29.8691 1.12316H18.5184C17.5011 1.12316 16.5221 1.51077 15.7804 2.2071L13.6162 4.23917C12.8746 4.93551 11.8955 5.32311 10.8782 5.32311H0.90625"
      stroke="url(#paint0_radial_2899_799)"
      strokeWidth="2"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2899_799"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.3985 13.5267) rotate(174.526) scale(31.4318 172404)"
      >
        <stop stopColor="#DBDB8E" />
        <stop offset="1" stopColor="#8DCCE7" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
export default MobileDecorativeVectorLeft;
