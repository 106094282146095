import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Components
import Homepage from "./pages/Homepage/Homepage";
import Layout from "./components/Layout/Layout";
import Error404 from "./components/Error404/Error404";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
