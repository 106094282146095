import { ethers } from "ethers";

import { checkIfCorrectNetwork, getMetamaskProvider, switchEthereumNetwork } from "./ethNetwork";
import { clearConnectState, setProvider } from "../store/reducers/connectSlice";
import { getInitialData, handleInitialActions } from "./main";
import { clearUserState } from "../store/reducers/userSlice";
import { dmtClient } from "./axios";
import { API_ROUTES } from "../config/api";
import { store } from "../store";
import { clearSwapState } from "../store/reducers/swapSlice";
import { setLoaderData, setMainError, setNavigationStep } from "../store/reducers/mainSlice";
import { NAVIGATION_STEPS } from "../config";

const handleAccountChanged = async () => {
  store.dispatch(handleDisconnect());
  store.dispatch(setMainError({ message: "Account changed, please connect again" }));
};

export const addAccountChangeListener = () => {
  try {
    window.ethereum.on("accountsChanged", handleAccountChanged);
  } catch {}
};

export const removeAccountChangeListener = () => {
  try {
    window.ethereum.removeListener("accountsChanged", handleAccountChanged);
  } catch {}
};

export const addChainChangeListener = () => {
  try {
    window.ethereum.on("chainChanged", () => {
      window.location.reload();
    });
  } catch {}
};

export const handleConnect = () => async dispatch => {
  dispatch(setMainError({ message: "" }));
  const metamaskProvider = getMetamaskProvider();

  if (!metamaskProvider) {
    dispatch(setMainError({ message: "Metamask is not installed" }));

    throw Error();
  }

  const provider = new ethers.BrowserProvider(metamaskProvider, "any");
  dispatch(setProvider(provider));

  const isCorrectNetwork = await checkIfCorrectNetwork(provider);

  if (!isCorrectNetwork) {
    try {
      await switchEthereumNetwork(provider);
    } catch {
      dispatch(setMainError({ message: "Incorrect network selected" }));

      throw Error();
    }
  }

  await dispatch(getInitialData());
  await dispatch(handleInitialActions());

  addAccountChangeListener();
  addChainChangeListener();
};

export const handleDisconnect = () => async dispatch => {
  dispatch(clearConnectState());
  dispatch(clearUserState());
  dispatch(clearSwapState());
  dispatch(setNavigationStep(NAVIGATION_STEPS.connect));
  removeAccountChangeListener();
  dmtClient.defaults.headers.common["Authorization"] = "";
};

export const handleAttachWallet = playerId => async (dispatch, getState) => {
  const { provider, wallet } = getState().connect;

  try {
    dispatch(setLoaderData({ isLoading: true, loadingText: "Waiting for personal sign" }));
    const payload = {
      player_id: playerId,
      wallet_addr: wallet,
    };
    const signature = await provider.send("personal_sign", [JSON.stringify(payload), wallet]);

    await dmtClient.post(API_ROUTES.connectWallet, { payload, signature });
  } catch (error) {
    dispatch(setMainError({ message: "An error occurred while attaching wallet" }));

    throw error;
  } finally {
    dispatch(setLoaderData({ isLoading: false }));
  }
};
