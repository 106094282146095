import { Contract } from "ethers";
import { abiStorage } from "../config/contracts";

export const getContract = async ({ name, address, signer, provider }) => {
  const abi = abiStorage[name].abi;

  return new Contract(address, abi, signer || provider);
};

export const connectSigner = async (provider, contract) => {
  const signer = await provider.getSigner();

  return contract.connect(signer);
};

export const approve = async ({ tokenAddress, soldAmount, approvedAddress, provider }) => {
  const tokenContract = await getContract({
    address: tokenAddress,
    provider,
    name: "Erc20",
  });
  const tokenContractSigned = await connectSigner(provider, tokenContract);
  const tx = await tokenContractSigned.approve(approvedAddress, soldAmount);

  return await tx.wait();
};
