import { useDispatch, useSelector } from "react-redux";
import { setSuccessModal } from "../../../store/reducers/mainSlice";
import Modal from "../Modal/Modal";
import styles from "./styles.module.scss";
import { MainButton } from "../../buttons/MainButton/MainButton";
import { closePage } from "../../../utils/common";
import { DecorativeVectorLeft } from "./icons/DecorativeVectorLeft";
import { DecorativeVectorRight } from "./icons/DecorativeVectorRight";
import { MobileDecorativeVectorLeft } from "./icons/MobileDecorativeVectorLeft";
import { MobileDecorativeVectorRight } from "./icons/MobileDecorativeVectorRight";

const SuccessModal = () => {
  const isOpen = useSelector(s => s.main.successModal.isOpen);
  const isClosePage = useSelector(s => s.main.successModal.isClosePage);
  const isMobile = useSelector(s => s.main.isMobile);
  const isMetamaskBrowser = useSelector(s => s.main.isMetamaskBrowser);
  const dispatch = useDispatch();

  const closeModal = () => {
    if (isClosePage) {
      closePage(isMetamaskBrowser);
    } else {
      dispatch(setSuccessModal({ isOpen: false }));
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="Success Modal">
      {!isMobile && <div className={styles.wingBottom} />}
      <div className={styles.textWrapper}>
        {isMobile ? (
          <>
            <MobileDecorativeVectorLeft className={styles.decorativeVectorLeft} />
            <MobileDecorativeVectorRight className={styles.decorativeVectorRight} />
          </>
        ) : (
          <>
            <DecorativeVectorLeft className={styles.decorativeVectorLeft} />
            <DecorativeVectorRight className={styles.decorativeVectorRight} />
          </>
        )}
        <p className={styles.text}>Successful operation</p>
      </div>
      <MainButton onClick={() => closePage(isMetamaskBrowser)}>Return to game</MainButton>
    </Modal>
  );
};

export default SuccessModal;
