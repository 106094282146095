import { configureStore } from "@reduxjs/toolkit";
import connectReducer from "./reducers/connectSlice";
import mainReducer from "./reducers/mainSlice";
import userReducer from "./reducers/userSlice";
import swapReducer from "./reducers/swapSlice";
import historyReducer from "./reducers/historySlice";

BigInt.prototype.toJSON = function () {
  return this.toString();
};

export const store = configureStore({
  reducer: {
    main: mainReducer,
    connect: connectReducer,
    user: userReducer,
    swap: swapReducer,
    history: historyReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["connect/setProvider", "swap/setSwapRates", "main/setReassignModal"],
        ignoredPaths: ["connect.provider", "swap.buyRate", "swap.sellRate", /^main\.reassignModal/],
      },
    }),
});
