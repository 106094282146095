import { useCallback, useReducer } from "react";

const ACTION_TYPES = {
  SET_ERROR: "SET_ERROR",
  SET_SUCCESS: "SET_SUCCESS",
  RESET: "RESET",
};

const STATUS_TYPES = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const initialState = {
  text: "",
  type: STATUS_TYPES.SUCCESS,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ERROR:
      return {
        text: action.text,
        type: STATUS_TYPES.ERROR,
      };
    case ACTION_TYPES.SET_SUCCESS:
      return {
        text: action.text,
        type: STATUS_TYPES.SUCCESS,
      };

    case ACTION_TYPES.RESET: {
      return initialState;
    }

    default:
      return state;
  }
};

export const useSwapCurrentStatus = () => {
  const [currentStatus, dispatch] = useReducer(reducer, initialState);

  const setErrorStatus = useCallback(text => {
    dispatch({ type: ACTION_TYPES.SET_ERROR, text });
  }, []);

  const setSuccessStatus = useCallback(text => {
    dispatch({ type: ACTION_TYPES.SET_SUCCESS, text });
  }, []);

  const resetErrorStatus = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, []);

  return { currentStatus, setErrorStatus, setSuccessStatus, resetErrorStatus, STATUS_TYPES };
};
