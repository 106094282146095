const MobileConnectButtonBackground = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="239"
    height="124"
    viewBox="0 0 239 124"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_d_2899_801)">
      <path
        d="M51.7422 52.2003V66.77C51.7422 67.8746 52.6376 68.77 53.7422 68.77H182.617C183.132 68.77 183.628 68.571 184 68.2145L187.14 65.2073C187.534 64.8301 187.757 64.3083 187.757 63.7628V49.3115C187.757 48.2069 186.862 47.3115 185.757 47.3115H56.863C56.3592 47.3115 55.874 47.5016 55.5044 47.8438L52.3836 50.7326C51.9747 51.1111 51.7422 51.6431 51.7422 52.2003Z"
        fill="url(#paint0_linear_2899_801)"
      />
      <path
        d="M51.7422 52.2003V66.77C51.7422 67.8746 52.6376 68.77 53.7422 68.77H182.617C183.132 68.77 183.628 68.571 184 68.2145L187.14 65.2073C187.534 64.8301 187.757 64.3083 187.757 63.7628V49.3115C187.757 48.2069 186.862 47.3115 185.757 47.3115H56.863C56.3592 47.3115 55.874 47.5016 55.5044 47.8438L52.3836 50.7326C51.9747 51.1111 51.7422 51.6431 51.7422 52.2003Z"
        stroke="url(#paint1_linear_2899_801)"
        strokeWidth="2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2899_801"
        x="0.742188"
        y="0.311462"
        width="238.016"
        height="123.459"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="25" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.568627 0 0 0 0 0.882353 0 0 0 0 0.862745 0 0 0 0.4 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2899_801" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2899_801"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2899_801"
        x1="51.7422"
        y1="47.3115"
        x2="207.646"
        y2="85.7627"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.183111" stopColor="#B2E8FF" />
        <stop offset="0.432593" stopColor="#B1FFEB" />
        <stop offset="0.854443" stopColor="#FFF8B4" />
        <stop offset="1" stopColor="#FFF9B9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2899_801"
        x1="190.139"
        y1="68.77"
        x2="53.5036"
        y2="30.5907"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.473958" stopColor="#91CBE6" />
        <stop offset="0.96875" stopColor="#DED198" />
      </linearGradient>
    </defs>
  </svg>
);
export default MobileConnectButtonBackground;
