const MobileClosePageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M0.417969 0.416656H4.58464V4.58332H0.417969V0.416656ZM8.7513 8.74999H4.58464V4.58332H8.7513V8.74999ZM12.918 12.9167H8.7513V8.74999H12.918V12.9167ZM17.0846 12.9167H12.918V17.0833H8.7513V21.25H4.58464V25.4167H0.417969V29.5833H4.58464V25.4167H8.7513V21.25H12.918V17.0833H17.0846V21.25H21.2513V25.4167H25.418V29.5833H29.5846V25.4167H25.418V21.25H21.2513V17.0833H17.0846V12.9167ZM21.2513 8.74999V12.9167H17.0846V8.74999H21.2513ZM25.418 4.58332V8.74999H21.2513V4.58332H25.418ZM25.418 4.58332V0.416656H29.5846V4.58332H25.418Z"
        fill="#129BFF"
      />
    </svg>
  );
};

export default MobileClosePageIcon;
