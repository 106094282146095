const DecorativeVectorLeft = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={230} height={19} fill="none" className={className}>
    <path
      stroke="url(#a)"
      strokeWidth={2}
      d="M230 18H99.127a4 4 0 0 1-2.161-.634L72.467 1.634A4 4 0 0 0 70.306 1H39.465a4 4 0 0 0-2.655 1.008l-8.318 7.379a4 4 0 0 1-2.654 1.008H0"
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-74.07207 6.7059 -34787.96522 -384261.50128 67.452 28.745)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDB8E" />
        <stop offset={1} stopColor="#8DCCE7" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);
export default DecorativeVectorLeft;
