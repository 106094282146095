import classNames from "classnames";
import { useSelector } from "react-redux";
import MainButtonBackground from "./MainButtonBackground";
import styles from "./styles.module.scss";
import { useMemo } from "react";
import { getRandomInt } from "../../../utils/common";
import Button from "../Button";
import MobileMainButtonBackground from "./MobileMainButtonBackground";

export const MainButton = ({
  className,
  type = "button",
  disabled,
  onClick,
  children,
  ...restProps
}) => {
  const svgId = useMemo(() => getRandomInt(1, 1000), []);
  const isMobile = useSelector(s => s.main.isMobile);

  return (
    <>
      <Button
        type={type}
        className={classNames(styles.button, {
          [className]: !!className,
        })}
        disabled={disabled}
        onClick={onClick}
        {...restProps}
      >
        {isMobile ? (
          <MobileMainButtonBackground className={styles.background} id={svgId} />
        ) : (
          <MainButtonBackground className={styles.background} id={svgId} />
        )}

        <div className={styles.content}>{children}</div>
      </Button>
    </>
  );
};
