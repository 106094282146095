export const DecorativeVectorLeft = ({ className }) => (
  <svg
    width="140"
    height="15"
    viewBox="0 0 140 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M140 14H82.296L67.5795 1H32.9184H-2.42889e-06"
      stroke="url(#paint0_radial_1202_979)"
      strokeWidth="2"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1202_979"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(59.2337 -2.33912) rotate(-6.84051) scale(45.7643 78658.6)"
      >
        <stop stopColor="#119BFF" />
        <stop offset="1" stopColor="#0075FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
