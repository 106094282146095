import { setChainId, setWallet } from "../store/reducers/connectSlice";
import { setPlayerId } from "../store/reducers/userSlice";
import { setIsSellTokenEnabled, setSwapAddresses, setSwapRates } from "../store/reducers/swapSlice";
import { handleAttachWallet } from "./connect";
import { dmtClient } from "./axios";
import { getContract } from "./contract";
import { API_ROUTES, EXCHANGE_ADDRESS } from "../config/api";
import {
  setIsMetamaskBrowser,
  setLoaderData,
  setMainError,
  setNavigationStep,
  setReassignModal,
} from "../store/reducers/mainSlice";
import { NAVIGATION_STEPS } from "../config";
import { handleInDmdPayload } from "./swap";
import uaParserJs from "ua-parser-js";

export const getWallet = () => async (dispatch, getState) => {
  const provider = getState().connect.provider;
  const wallets = await provider.send("eth_requestAccounts");

  const userWallet = wallets[0];
  dispatch(setWallet(userWallet));
};

export const getInitialData = () => async (dispatch, getState) => {
  dispatch(setLoaderData({ isLoading: true }));

  try {
    await dispatch(getWallet());
    const { provider } = getState().connect;
    const chainId = await provider.send("eth_chainId");

    if (chainId) {
      dispatch(setChainId(chainId));
    }

    const exchangeContract = await getContract({
      name: "DMDExchange",
      address: EXCHANGE_ADDRESS,
      provider,
    });

    const isExchangeContractPaused = await exchangeContract.paused();

    if (isExchangeContractPaused) {
      throw Error("Exchange contract is paused");
    }

    const dmdTokenAddress = await exchangeContract.dmdTokenAddress();
    const externalTokenAddress = await exchangeContract.externalTokenAddress();

    const dmdTokenContract = await getContract({
      name: "DMDToken",
      address: dmdTokenAddress,
      provider,
    });

    const isDMDTokenContractPaused = await dmdTokenContract.paused();

    if (isDMDTokenContractPaused) {
      throw Error("DMDToken contract is paused");
    }

    dispatch(
      setSwapAddresses({
        dmdTokenAddress,
        externalTokenAddress,
      }),
    );

    const buyRate = await exchangeContract.buyRate();
    const sellRate = await exchangeContract.sellRate();
    const isSellTokenEnabled = await exchangeContract.sellTokenEnabled();

    dispatch(setSwapRates({ buyRate, sellRate }));
    dispatch(setIsSellTokenEnabled(isSellTokenEnabled));

    const inDmdSwapPayload = getState().main.queryInDmdSwapPayload;

    if (inDmdSwapPayload) {
      dispatch(handleInDmdPayload());
    }
  } catch (error) {
    let errorMessage;

    if (error.code === "ACTION_REJECTED") {
      errorMessage = error.shortMessage;
    } else {
      errorMessage = error.error?.message || error.message;
    }

    dispatch(setMainError({ message: errorMessage }));

    throw error;
  } finally {
    dispatch(setLoaderData({ isLoading: false }));
  }
};

export const handleInitialActions = () => async (dispatch, getState) => {
  const { queryToken } = getState().main;
  const { wallet } = getState().connect;
  let authResponse;

  try {
    authResponse = await dmtClient.post(API_ROUTES.auth, {
      token: queryToken,
    });
  } catch (error) {
    let errorMessage;

    if (error.response?.data.token) {
      errorMessage = "Session expired, please try again through Game";
    } else {
      errorMessage = "Auth server request error";
    }

    dispatch(setMainError({ message: errorMessage }));

    throw error;
  }

  const attachedWallet = authResponse.data.main_wallet;
  const playerId = authResponse.data.player_id;
  dmtClient.defaults.headers.common["Authorization"] = `Bearer ${authResponse.data.token}`;

  if (attachedWallet && attachedWallet !== wallet) {
    await new Promise((resolve, reject) => {
      dispatch(
        setReassignModal({
          isOpen: true,
          onConfirm: resolve,
          onDecline: reject,
          oldWallet: attachedWallet,
        }),
      );
    });
    await dispatch(handleAttachWallet(playerId));
  }

  dispatch(setPlayerId(playerId));

  if (!attachedWallet) {
    await dispatch(handleAttachWallet(playerId));
  }

  const inDmdSwapPayload = getState().main.queryInDmdSwapPayload;
  const nextNavigationStep = inDmdSwapPayload
    ? NAVIGATION_STEPS.inDmdSwap
    : NAVIGATION_STEPS.operationSelect;
  dispatch(setNavigationStep(nextNavigationStep));
};

export const detectMetamaskBrowser = () => async dispatch => {
  const parsedUserAgent = uaParserJs(window.navigator.userAgent);
  const isMobileDevice = parsedUserAgent.device?.type === "mobile";
  const isMetamaskBrowser = isMobileDevice && !!window.ethereum;

  dispatch(setIsMetamaskBrowser(isMetamaskBrowser));
};
