import React, { useCallback, useEffect, useRef } from "react";
import Loader from "../Loader/Loader";

const InfiniteScroll = ({
  observerOptions,
  loadingParams,
  onLoadMore,
  isLoading,
  children,
  hasMore,
  loaderClassname,
}) => {
  const loaderRef = useRef();

  const intersectionHandler = useCallback(
    ([target]) => {
      if (target.isIntersecting && hasMore && !isLoading) {
        onLoadMore(loadingParams);
      }
    },
    [hasMore, onLoadMore, loadingParams, isLoading],
  );

  useEffect(() => {
    const loader = loaderRef.current;
    const options = {
      rootMargin: `100px`,
      ...observerOptions,
    };

    const observer = new IntersectionObserver(intersectionHandler, options);

    if (loader) {
      observer.observe(loader);
    }

    if (!hasMore) {
      observer.unobserve(loader);
    }

    return () => observer.unobserve(loader);
  }, [loaderRef, intersectionHandler, hasMore, observerOptions]);

  return (
    <>
      {children}
      <div ref={loaderRef} />
      {hasMore && <Loader className={loaderClassname} />}
    </>
  );
};

export default InfiniteScroll;
