import { useDispatch, useSelector } from "react-redux";
import { goBack } from "../../../store/reducers/mainSlice";
import { NAVIGATION_STEPS } from "../../../config";
import styles from "./styles.module.scss";
import Button from "../Button";

const BackButton = () => {
  const dispatch = useDispatch();
  const navigationStep = useSelector(s => s.main.navigationSteps.current);
  const navigationStepsHistory = useSelector(s => s.main.navigationSteps.history);

  const onClick = () => {
    dispatch(goBack());
  };

  if (
    navigationStep === NAVIGATION_STEPS.connect ||
    navigationStep === NAVIGATION_STEPS.inDmdSwap
  ) {
    return null;
  }

  return (
    <Button className={styles.button} onClick={onClick} disabled={!navigationStepsHistory.length}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14 7L9 12L14 17"
          stroke="#119BFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Back
    </Button>
  );
};

export default BackButton;
