const DesktopClosePageIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 39.875C18.4647 39.875 15.0087 38.8267 12.0692 36.8625C9.12966 34.8984 6.83858 32.1067 5.48566 28.8405C4.13274 25.5742 3.77876 21.9802 4.46847 18.5128C5.15818 15.0454 6.86061 11.8603 9.36047 9.36047C11.8603 6.86061 15.0454 5.15818 18.5128 4.46847C21.9802 3.77876 25.5742 4.13274 28.8405 5.48566C32.1067 6.83858 34.8984 9.12966 36.8625 12.0692C38.8267 15.0087 39.875 18.4647 39.875 22C39.875 26.7408 37.9918 31.2873 34.6395 34.6395C31.2873 37.9918 26.7408 39.875 22 39.875ZM22 6.87501C19.0086 6.87501 16.0843 7.76207 13.597 9.42403C11.1097 11.086 9.17111 13.4482 8.02633 16.2119C6.88155 18.9757 6.58203 22.0168 7.16563 24.9508C7.74923 27.8847 9.18975 30.5797 11.305 32.695C13.4203 34.8103 16.1153 36.2508 19.0493 36.8344C21.9832 37.418 25.0244 37.1185 27.7881 35.9737C30.5518 34.8289 32.914 32.8903 34.576 30.403C36.2379 27.9157 37.125 24.9915 37.125 22C37.125 17.9886 35.5315 14.1415 32.695 11.305C29.8585 8.46853 26.0114 6.87501 22 6.87501Z"
        fill="#119BFF"
      />
      <path
        d="M16.1691 29.2049C15.9881 29.2059 15.8087 29.1712 15.6412 29.1028C15.4737 29.0344 15.3213 28.9336 15.1928 28.8061C14.9367 28.5485 14.793 28.2 14.793 27.8368C14.793 27.4735 14.9367 27.125 15.1928 26.8674L26.8666 15.1936C26.9939 15.0618 27.1459 14.9564 27.3139 14.8833C27.482 14.8102 27.6628 14.771 27.846 14.7678C28.0292 14.7646 28.2113 14.7975 28.3818 14.8647C28.5523 14.9319 28.7079 15.0319 28.8397 15.1592C28.9715 15.2865 29.0769 15.4386 29.15 15.6066C29.2231 15.7747 29.2623 15.9555 29.2655 16.1387C29.2687 16.3219 29.2358 16.504 29.1686 16.6745C29.1014 16.845 29.0014 17.0006 28.8741 17.1324L17.1316 28.8749C16.8647 29.1039 16.5203 29.222 16.1691 29.2049Z"
        fill="#119BFF"
      />
      <path
        d="M27.8302 29.2052C27.4694 29.2036 27.1238 29.0604 26.8677 28.8064L15.1939 17.1327C14.9686 16.8696 14.8509 16.5313 14.8643 16.1852C14.8777 15.8392 15.0211 15.5109 15.266 15.266C15.5109 15.0211 15.8392 14.8777 16.1852 14.8643C16.5313 14.8509 16.8696 14.9686 17.1327 15.1939L28.8752 26.8677C29.0125 26.9963 29.1221 27.1517 29.1969 27.3244C29.2718 27.497 29.3104 27.6832 29.3104 27.8714C29.3104 28.0596 29.2718 28.2458 29.1969 28.4184C29.1221 28.5911 29.0125 28.7465 28.8752 28.8752C28.7329 28.9989 28.5667 29.092 28.387 29.1488C28.2072 29.2055 28.0177 29.2247 27.8302 29.2052Z"
        fill="#119BFF"
      />
    </svg>
  );
};

export default DesktopClosePageIcon;
