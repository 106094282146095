export const MobileDecorativeVectorRight = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="67"
    height="8"
    viewBox="0 0 67 8"
    fill="none"
    className={className}
  >
    <path
      d="M0.855469 6.98743H26.3552L32.2595 1.11523H50.4857H59.385H67.0006"
      stroke="url(#paint0_radial_2951_990)"
      strokeWidth="0.901669"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2951_990"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.5459 16.5168) rotate(171) scale(20.3298 45653.7)"
      >
        <stop stopColor="#119BFF" />
        <stop offset="1" stopColor="#0075FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
