import { createSlice } from "@reduxjs/toolkit";
import { DMD_TOKEN_DATA, EXTERNAL_TOKEN_DATA } from "../../config/tokens";

const initialState = {
  tokenFrom: {
    address: "",
    name: DMD_TOKEN_DATA.name,
  },
  tokenTo: {
    address: "",
    name: EXTERNAL_TOKEN_DATA.name,
  },
  balance: {
    from: "0",
    to: "0",
  },
  buyRate: "1",
  sellRate: "1",
  isSellTokenEnabled: false,
  addresses: {
    dmdToken: "",
    externalToken: "",
  },
  inDmdSwapData: {
    decodedPayload: {},
    parsedPayload: {},
  },
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    setSwapTokens: (state, action) => {
      const { tokenFrom, tokenTo } = action.payload;

      state.tokenFrom = tokenFrom || {};
      state.tokenTo = tokenTo || {};
    },
    setSwapBalances: (state, action) => {
      const { balanceFrom, balanceTo } = action.payload;

      state.balance.from = balanceFrom;
      state.balance.to = balanceTo;
    },
    setSwapRates: (state, action) => {
      const { buyRate, sellRate } = action.payload;

      state.buyRate = buyRate;
      state.sellRate = sellRate;
    },
    setIsSellTokenEnabled: (state, action) => {
      state.isSellTokenEnabled = action.payload;
    },
    setSwapAddresses: (state, action) => {
      const { dmdTokenAddress, externalTokenAddress } = action.payload;

      state.addresses.dmdToken = dmdTokenAddress;
      state.addresses.externalToken = externalTokenAddress;
    },
    setInDmdSwapData: (state, action) => {
      state.inDmdSwapData = action.payload;
    },
    clearSwapState: () => initialState,
  },
});

export const {
  setSwapTokens,
  setSwapBalances,
  setSwapRates,
  setIsSellTokenEnabled,
  clearSwapState,
  setSwapAddresses,
  setInDmdSwapData,
} = swapSlice.actions;

export default swapSlice.reducer;
