import Input from "../Input/Input";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getAmount } from "../../utils/amount";
import styles from "./styles.module.scss";
import classNames from "classnames";

const TokenInput = ({ name, label, readOnly, setManualInput, tokenData, balance }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();
  const errorMessage = errors[name]?.message;

  const onInputChange = async event => {
    const value = event.target?.value?.trim();
    const isReverse = name === "to";
    setManualInput(name);

    let amount;

    try {
      amount = await dispatch(
        getAmount({
          amount: value,
          isReverse,
        }),
      );
    } catch (error) {
      amount = 0;
    }

    setValue(isReverse ? "from" : "to", amount, { shouldValidate: true });
  };

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputWrapperHeader}>
        <p className={styles.inputLabel}>{label}</p>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
      <div
        className={classNames(styles.clipPathBorderWrapper, {
          [styles.clipPathBorderWrapperError]: !!errorMessage,
        })}
      >
        <div className={styles.inputWrapperContent}>
          <div className={styles.tokenMetaBlock}>
            <div className={styles.tokenImageWrapper}>
              <img
                loading="lazy"
                src={tokenData.imgSrc}
                width={44}
                height={44}
                alt={tokenData.name}
              />
            </div>
            <div className={styles.tokenMetaContent}>
              <p className={styles.tokenName}>{tokenData.name}</p>
              <p className={styles.tokenFullName}>{tokenData.fullName}</p>
            </div>
          </div>
          <div className={styles.inputWithBalanceWrapper}>
            <p className={styles.balance}>
              Available {balance} {tokenData.name}
            </p>
            <Input
              name={name}
              readOnly={readOnly}
              onInput={onInputChange}
              className={styles.input}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenInput;
