const MobileMainButtonBackground = ({ className, id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="219"
    height="81"
    viewBox="0 0 219 81"
    fill="none"
    className={className}
  >
    <g filter={`url(#a.mainButton.${id})`}>
      <path
        d="M35.4366 52.5552H69.4056H109.624H193.412V38.1587V31.2483L184.102 23.7621H109.624H25.8359V38.1587V45.357L35.4366 52.5552Z"
        fill={`url(#b.mainButton.${id})`}
      />
      <path
        d="M26.4118 45.069L35.6285 51.9794H69.4056H109.624H192.836V38.1587V31.5242L183.899 24.338H109.624H26.4118V38.1587V45.069Z"
        stroke={`url(#c.mainButton.${id})`}
        strokeWidth="1.15172"
      />
    </g>
    <defs>
      <filter
        id={`a.mainButton.${id}`}
        x="0.49799"
        y="0.727648"
        width="218.25"
        height="79.469"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.30345" />
        <feGaussianBlur stdDeviation="12.669" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.964706 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2899_901" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2899_901"
          result="shape"
        />
      </filter>
      <linearGradient
        id={`b.mainButton.${id}`}
        x1="25.8359"
        y1="52.5552"
        x2="219.66"
        y2="8.66197"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.183111" stopColor="#FF56F6" />
        <stop offset="0.432593" stopColor="#B936EE" />
        <stop offset="0.854443" stopColor="#3BACE2" />
        <stop offset="1" stopColor="#406AFF" />
      </linearGradient>
      <linearGradient
        id={`c.mainButton.${id}`}
        x1="196.346"
        y1="23.7621"
        x2="26.0711"
        y2="67.4493"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.473958" stopColor="#FFA6FA" />
        <stop offset="0.96875" stopColor="#3C79D5" />
      </linearGradient>
    </defs>
  </svg>
);
export default MobileMainButtonBackground;
