import { formatEther, parseEther } from "ethers";
import { approve, connectSigner, getContract } from "./contract";
import { EXCHANGE_ADDRESS, GAME_BRIDGE_ADDRESS } from "../config/api";
import { setInDmdSwapData, setSwapTokens } from "../store/reducers/swapSlice";
import { DMD_TOKEN_DATA, EXTERNAL_TOKEN_DATA } from "../config/tokens";
import { getBalances } from "./balance";
import { setLoaderData, setMainError, setSuccessModal } from "../store/reducers/mainSlice";
import base64 from "base-64";
import { getAmount } from "./amount";

export const setBuyMode = () => async (dispatch, getState) => {
  const { addresses } = getState().swap;
  dispatch(
    setSwapTokens({
      tokenFrom: {
        ...EXTERNAL_TOKEN_DATA,
        address: addresses.externalToken,
      },
      tokenTo: {
        ...DMD_TOKEN_DATA,
        address: addresses.dmdToken,
      },
    }),
  );
  await dispatch(getBalances());
};

export const setSellMode = () => async (dispatch, getState) => {
  const { addresses } = getState().swap;
  dispatch(
    setSwapTokens({
      tokenFrom: {
        ...DMD_TOKEN_DATA,
        address: addresses.dmdToken,
      },
      tokenTo: {
        ...EXTERNAL_TOKEN_DATA,
        address: addresses.externalToken,
      },
    }),
  );
  await dispatch(getBalances());
};

export const tokenToTokenSwap = amount => async (dispatch, getState) => {
  const { tokenFrom, addresses } = getState().swap;
  const { provider } = getState().connect;
  dispatch(setLoaderData({ isLoading: true, loadingText: "Waiting for approve" }));

  const exchangeContract = await getContract({
    name: "DMDExchange",
    address: EXCHANGE_ADDRESS,
    provider,
  });
  const tokenFromSold = parseEther(String(amount));
  const isBuyMode = tokenFrom.address === addresses.externalToken;

  if (isBuyMode) {
    await approve({
      tokenAddress: tokenFrom.address,
      soldAmount: tokenFromSold,
      approvedAddress: EXCHANGE_ADDRESS,
      provider,
    });
  }

  const exchangeContractSigned = await connectSigner(provider, exchangeContract);

  dispatch(setLoaderData({ loadingText: "Waiting for transaction" }));
  const method = isBuyMode ? "buyToken" : "sellToken";
  const targetAmount = isBuyMode ? await dispatch(getAmount({ amount })) : amount;
  const tx = await exchangeContractSigned[method](parseEther(String(targetAmount)));

  return await tx.wait();
};

export const handleInDmdPayload = () => (dispatch, getState) => {
  try {
    const inDmdSwapPayload = getState().main.queryInDmdSwapPayload;
    const decodedPayload = JSON.parse(base64.decode(inDmdSwapPayload));

    if (!Array.isArray(decodedPayload)) {
      throw Error();
    }

    const inDmdAmount = formatEther(BigInt(decodedPayload[1]));
    const isOutput = decodedPayload[2];
    dispatch(
      setInDmdSwapData({
        decodedPayload,
        parsedPayload: {
          amount: inDmdAmount,
          isOutput,
        },
      }),
    );
  } catch (error) {
    throw Error("Incorrect payload, please try again through Game");
  }
};

export const handleInDmdSwap = () => async (dispatch, getState) => {
  try {
    dispatch(
      setLoaderData({
        isLoading: true,
        loadingText: "Waiting for transaction",
      }),
    );
    const inDmdSwapData = getState().swap.inDmdSwapData.decodedPayload;
    const provider = getState().connect.provider;
    const gameBridgeContract = await getContract({
      name: "DMDGameTransferBridge",
      address: GAME_BRIDGE_ADDRESS,
      provider,
    });
    const gameBridgeContractSigned = await connectSigner(provider, gameBridgeContract);
    const tx = await gameBridgeContractSigned.process(...inDmdSwapData);
    await tx.wait();
    dispatch(setSuccessModal({ isOpen: true, isClosePage: true }));
  } catch (error) {
    let errorMessage;

    if (error.code === "ACTION_REJECTED") {
      errorMessage = error.shortMessage;
    } else {
      errorMessage = "Execution reverted, please try again through Game";
    }

    console.dir(error);
    dispatch(setMainError({ message: errorMessage }));
  } finally {
    dispatch(
      setLoaderData({
        isLoading: false,
        loadingText: "Waiting for transaction",
      }),
    );
  }
};
