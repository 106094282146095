import { NETWORK_PARAMS } from "../config";

export const getMetamaskProvider = () => {
  if (!window.ethereum) return null;

  const isMultipleProviders = !!window.ethereum.providers?.length;

  if (isMultipleProviders) {
    return window.ethereum.providers.find(provider => provider.isMetaMask);
  } else {
    return window.ethereum;
  }
};

export const checkIfCorrectNetwork = async provider => {
  try {
    const userChainId = await provider.send("eth_chainId");

    return userChainId === `0x${Number(NETWORK_PARAMS.chainId).toString(16)}`;
  } catch (e) {
    return false;
  }
};

export const switchEthereumNetwork = async provider => {
  try {
    // check if the chain to connect to is installed
    await provider.send("wallet_switchEthereumChain", [
      {
        chainId: NETWORK_PARAMS.chainId,
      },
    ]);
  } catch (error) {
    const errorCode = error.error?.code || error.data?.originalError?.code;

    // This error code indicates that the chain has not been added to MetaMask
    if (errorCode) {
      try {
        await provider.send("wallet_addEthereumChain", [NETWORK_PARAMS]);

        return;
      } catch (addError) {
        console.error(addError);

        throw addError;
      }
    }

    throw error;
  }
};
