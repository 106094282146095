const MainButtonBackground = ({ className, id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={379}
    height={138}
    fill="none"
    className={className}
  >
    <g filter={`url(#a.mainButton.${id})`}>
      <path fill={`url(#b.mainButton.${id})`} d="M60.672 90H335V53l-16.167-13H44v37.5L60.672 90Z" />
      <path
        stroke={`url(#c.mainButton.${id})`}
        strokeWidth={2}
        d="m45 77 16.005 12H334V53.48L318.481 41H45v36Z"
      />
    </g>
    <defs>
      <linearGradient
        id={`b.mainButton.${id}`}
        x1={44}
        x2={380.581}
        y1={90}
        y2={13.778}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.183} stopColor="#FF56F6" />
        <stop offset={0.433} stopColor="#B936EE" />
        <stop offset={0.854} stopColor="#3BACE2" />
        <stop offset={1} stopColor="#406AFF" />
      </linearGradient>
      <linearGradient
        id={`c.mainButton.${id}`}
        x1={340.095}
        x2={44.408}
        y1={40}
        y2={115.864}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.474} stopColor="#FFA6FA" />
        <stop offset={0.969} stopColor="#3C79D5" />
      </linearGradient>
      <filter
        id={`a.mainButton.${id}`}
        width={379}
        height={138}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={22} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.964706 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_911_776" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_911_776" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default MainButtonBackground;
