import DesktopClosePageIcon from "./DesktopClosePageIcon";
import styles from "./styles.module.scss";
import BackButton from "../buttons/BackButton/BackButton";
import { closePage } from "../../utils/common";
import Button from "../buttons/Button";
import { useSelector } from "react-redux";
import MobileClosePageIcon from "./MobileClosePageIcon";

const OuterButtonsWrapper = ({ children }) => {
  const isMobile = useSelector(s => s.main.isMobile);
  const isMetamaskBrowser = useSelector(s => s.main.isMetamaskBrowser);

  return (
    <>
      {isMobile && (
        <Button className={styles.closePageButton} onClick={() => closePage(isMetamaskBrowser)}>
          <MobileClosePageIcon />
        </Button>
      )}
      <div className={styles.wrapper}>
        <BackButton />
        {!isMobile && (
          <Button className={styles.closePageButton} onClick={() => closePage(isMetamaskBrowser)}>
            <DesktopClosePageIcon />
          </Button>
        )}
        {children}
      </div>
    </>
  );
};

export default OuterButtonsWrapper;
