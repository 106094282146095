import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import ConnectButtonBackground from "./icons/ConnectButtonBackground";
import DecorativeVectorLeft from "./icons/DecorativeVectorLeft";
import DecorativeVectorRight from "./icons/DecorativeVectorRight";
import MobileConnectButtonBackground from "./icons/MobileConnectButtonBackground";
import MobileDecorativeVectorLeft from "./icons/MobileDecorativeVectorLeft";
import MobileDecorativeVectorRight from "./icons/MobileDecorativeVectorRight";

const ConnectButton = ({ onClick }) => {
  const isMobile = useSelector(s => s.main.isMobile);

  return (
    <button onClick={onClick} className={styles.connectButton}>
      {isMobile ? (
        <>
          <MobileDecorativeVectorLeft className={styles.decorativeVectorLeft} />
          <MobileDecorativeVectorRight className={styles.decorativeVectorRight} />
          <MobileConnectButtonBackground className={styles.background} />
        </>
      ) : (
        <>
          <DecorativeVectorLeft className={styles.decorativeVectorLeft} />
          <DecorativeVectorRight className={styles.decorativeVectorRight} />
          <ConnectButtonBackground className={styles.background} />
        </>
      )}

      <div className={styles.content}>Connect Wallet</div>
    </button>
  );
};

export default ConnectButton;
