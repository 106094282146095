export const capitalizeString = string => {
  if (typeof string !== "string" || !string.length) return "";

  return string[0].toUpperCase() + string.slice(1);
};

export const getShortWallet = (wallet = "") => `${wallet.slice(0, 7)}...${wallet.slice(-5)}`;

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const closePage = isMetamaskBrowser => {
  if (isMetamaskBrowser) {
    window.open("com.interappa.dmntr://launch");
  } else {
    window.close();
  }
};
