import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useCallback } from "react";
import { HeaderBackgroundIcon } from "./HeaderBackgroundIcon";
import { DMD_TOKEN_DATA, EXTERNAL_TOKEN_DATA } from "../../config/tokens";
import InfiniteScroll from "../InfiniteScroll";
import { fetchMoreHistory } from "../../utils/history";

const COLUMNS = ["Spent", "Quantity", "Received", "Quantity", "Time"];

const HistoryBlock = () => {
  const dispatch = useDispatch();
  const historyDataList = useSelector(s => s.history.list);
  const isLoading = useSelector(s => s.history.isLoading);
  const hasMore = useSelector(s => s.history.hasMore);

  const onLoadMore = useCallback(() => {
    dispatch(fetchMoreHistory());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableHeader}>
        {COLUMNS.map((item, i) => (
          <p key={i} className={i === COLUMNS.length - 1 ? styles.timeHeaderColumn : null}>
            {item}
          </p>
        ))}
        <HeaderBackgroundIcon className={styles.tableHeaderLine} />
      </div>
      <div className={styles.tableContent}>
        <InfiniteScroll
          isLoading={isLoading}
          hasMore={hasMore}
          onLoadMore={onLoadMore}
          loaderClassname={styles.tableContentLoader}
        >
          {historyDataList.map((historyItem, i) => {
            const isBuyOperation = historyItem.operation === "buy_token";

            return (
              <Fragment key={i}>
                <p>{isBuyOperation ? EXTERNAL_TOKEN_DATA.name : DMD_TOKEN_DATA.name}</p>
                <p>
                  {Number(
                    isBuyOperation ? historyItem.external_amount : historyItem.dmd_amount,
                  ).toFixed(4)}
                </p>
                <p>{isBuyOperation ? DMD_TOKEN_DATA.name : EXTERNAL_TOKEN_DATA.name}</p>
                <p>
                  {Number(
                    isBuyOperation ? historyItem.dmd_amount : historyItem.external_amount,
                  ).toFixed(4)}
                </p>
                <p className={styles.timeItem}>{historyItem.created_at}</p>
              </Fragment>
            );
          })}
        </InfiniteScroll>
        {!historyDataList.length && !isLoading && (
          <p className={styles.emptyData}>History data is empty</p>
        )}
      </div>
    </div>
  );
};

export default HistoryBlock;
