const MobileDecorativeVectorRight = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="10"
    viewBox="0 0 80 10"
    fill="none"
    className={className}
  >
    <path
      d="M79.7405 1.57037H35.4586C34.6075 1.57037 33.7787 1.8418 33.0925 2.34519L26.0642 7.50135C25.378 8.00474 24.5491 8.27617 23.6981 8.27617H15.1024C14.0322 8.27617 13.0067 7.84731 12.255 7.08549L10.9483 5.76101C10.1966 4.99919 9.17106 4.57033 8.10086 4.57033H0.328125"
      stroke="url(#paint0_radial_2899_800)"
      strokeWidth="2"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2899_800"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.6174 -2.66812) rotate(-174.094) scale(25.7114 152007)"
      >
        <stop stopColor="#DBDB8E" />
        <stop offset="1" stopColor="#8DCCE7" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
export default MobileDecorativeVectorRight;
