import { formatEther, parseEther } from "ethers";
import { SWAP_RATE_MULTIPLIER } from "../config";

const getBuyAmount = (parsedAmount, rate, isReverse) =>
  isReverse
    ? (parsedAmount * SWAP_RATE_MULTIPLIER) / rate
    : (parsedAmount * rate) / SWAP_RATE_MULTIPLIER;

const getSellAmount = (parsedAmount, rate, isReverse) =>
  isReverse
    ? (parsedAmount * rate) / SWAP_RATE_MULTIPLIER
    : (parsedAmount * SWAP_RATE_MULTIPLIER) / rate;

export const getAmount =
  ({ amount, isReverse }) =>
  (dispatch, getState) => {
    if (!amount) return 0;

    const { buyRate, sellRate, tokenFrom, addresses } = getState().swap;
    const isBuyMode = tokenFrom.address === addresses.externalToken;
    const rate = isBuyMode ? buyRate : sellRate;
    const getAmountMethod = isBuyMode ? getBuyAmount : getSellAmount;
    const parsedAmount = parseEther(String(amount));
    const res = getAmountMethod(parsedAmount, rate, isReverse);

    return formatEther(res);
  };
