import styles from "./styles.module.scss";

const Error404 = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>404: Not Found</h1>
    </div>
  );
};

export default Error404;
