import styles from "./styles.module.scss";
import Button from "../Button";

const DeclineButton = ({ onClick }) => {
  return (
    <Button className={styles.button} onClick={onClick}>
      Decline
    </Button>
  );
};

export default DeclineButton;
