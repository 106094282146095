import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  provider: null,
  wallet: "",
  chainId: "",
};

const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    clearConnectState: () => initialState,
  },
});

export const { setProvider, setWallet, setChainId, clearConnectState } = connectSlice.actions;

export default connectSlice.reducer;
